<template>
    <div class="mt20 mb20">
        <div class="pd24 bg-white mb10">
            <BidDemandInfo :api-data="apiData" />
        </div>
        <div class="pd24 bg-white mb10">
            <BidForm v-if="has$needIDData" />
        </div>
    </div>
</template>

<script>
import BidForm from './Bid/BidForm';
import BidDemandInfo from './Bid/BidDemandInfo.vue';
import needGetByIDMixin from '@/mixins/needGetByID.mixin';

export default {
    components: {
        BidDemandInfo,
        BidForm,
    },
    mixins: [needGetByIDMixin],
    data() {
        return {
            billInfoForm: null,
            apiData: {},
        };
    },
    created() {
        this.get$needIDData();
        this.getData();
    },
    methods: {
        async getData(silent = false) {
            let id = this.$route.query.id;
            let res = await $service.needIntent.getIntentByNeedId(
                {
                    needId: id,
                },
                { silent },
            );

            this.apiData = _.get(res, 'data') || {};
        },
    },
};
</script>
