<template>
    <div>
        <Form ref="bidForm" :model="bidForm" :rules="ruleInline">
            <Row>
                <Col :span="8" class="pdr20">
                    <FormItem prop="brandId" label="品牌">
                        <Select
                            v-model="bidForm.brandId"
                            :disabled="bidForm.brandIdDisable"
                            placeholder="品牌"
                        >
                            <Option
                                v-for="it of options.brandId"
                                :key="it.text"
                                :value="it.value + ''"
                                :label-in-value="true"
                                @on-change="brandSelectChange"
                            >
                                {{ it.text }}
                            </Option>
                        </Select>
                    </FormItem>
                </Col>
                <Col :span="8">
                    <FormItem prop="productNo" label="货号">
                        <Input
                            v-model="bidForm.productNo"
                            :disabled="bidForm.productNoDisable"
                            type="text"
                            placeholder="货号"
                            clearable
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col :span="8" class="pdr20">
                    <FormItem prop="price" label="最优惠单价">
                        <InputNumber
                            v-model="bidForm.price"
                            class="wp100"
                            :min="0.01"
                            placeholder="单价"
                            clearable
                            @on-change="priceChange"
                        />
                    </FormItem>
                </Col>
                <Col :span="8" class="pdr20">
                    <FormItem prop="num" label="数量">
                        <!--                        <input-->
                        <!--                            v-model="bidForm.num"-->
                        <!--                            type="number"-->
                        <!--                            class="ivu-input"-->
                        <!--                            placeholder="数量"-->
                        <!--                            clearable-->
                        <!--                        />-->

                        <!-- 宽度 60% 单位后置 -->
                        <InputNumber
                            v-model="bidForm.num"
                            disabled
                            style="width: 60%"
                            :min="1"
                            :precision="0"
                            placeholder="数量"
                            clearable
                        />
                        <!-- 单位 补齐-->
                        <span v-if="bidForm.unit">{{ bidForm.unit }}</span>
                        <span v-if="bidForm.specs">{{ bidForm.specs }}</span>
                    </FormItem>
                </Col>
                <Col :span="8">
                    <FormItem prop="totalPrice" label="最优惠总价">
                        <input
                            :value="bidForm.totalPrice || $emptyContent"
                            disabled
                            type="number"
                            class="ivu-input"
                            placeholder="最优惠总价"
                        />
                        <!--                        <div class="inline-block pdl10">{{ bidForm.totalPrice || $emptyContent }}</div>-->
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col :span="8" class="pdr20">
                    <FormItem prop="stockType" label="现货类型">
                        <div class="ivu-input-fake">
                            <RadioGroup v-model="bidForm.stockType">
                                <Radio :label="1">国内现货</Radio>
                                <Radio :label="2">非国内现货</Radio>
                            </RadioGroup>
                        </div>
                    </FormItem>
                </Col>
                <Col :span="8" class="pdr20">
                    <FormItem prop="expectedReceiptTime" label="预计到货时间">
                        <div
                            slot="label"
                            class="pop-fix"
                            style="
                                display: inline-flex;
                                align-items: center;
                                position: relative;
                                z-index: 9;
                            "
                        >
                            <span>预计到货时间</span>
                            <Tooltip
                                content="付款后到货天数(不含付款日)"
                                placement="top"
                            >
                                <a-icon
                                    type="question-circle"
                                    class="ml5"
                                    theme="twoTone"
                                />
                            </Tooltip>
                        </div>
                        <div class="ivu-input-fake">
                            <span class="mr10">付款后</span>
                            <InputNumber
                                v-model="bidForm.expectedReceiptTime"
                                class="mr10 w100"
                                :min="1"
                                :step="1"
                                :precision="0"
                                placeholder="货期"
                                clearable
                            />
                            <span class="mr10">天</span>
                            <Tag
                                v-if="bidForm.stockType === 1"
                                size="default"
                                color="red"
                                >准</Tag
                            >
                        </div>
                    </FormItem>
                </Col>
            </Row>

            <Row>
                <Col :span="8" class="pdr20">
                    <FormItem prop="name" label="联系人">
                        <Input
                            v-model="bidForm.name"
                            type="text"
                            placeholder="联系人"
                            clearable
                        />
                    </FormItem>
                </Col>
                <Col :span="8">
                    <FormItem prop="phone" label="移动电话">
                        <Input
                            v-model="bidForm.phone"
                            type="text"
                            placeholder="移动电话"
                            clearable
                        />
                    </FormItem>
                </Col>
            </Row>
            <div class="flex-center">
                <Button class="mr10" type="primary" @click="save">
                    提交抢单
                </Button>
            </div>
        </Form>
    </div>
</template>

<script>
import needGetByIDMixin from '@/mixins/needGetByID.mixin';

export default {
    mixins: [needGetByIDMixin], // 必须有值才会展示此组件
    data() {
        return {
            bidForm: {
                needId: this.$route.query.id,
            },
            ruleInline: {
                brandId: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!value) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                productNo: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                productName: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                invoiceType: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                stockType: [
                    {
                        required: true,
                        message: '请选择现货类型',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!value) {
                                cb(new Error('现货类型不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                expectedReceiptTime: [
                    {
                        required: true,
                        // message: '不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!value) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            if (this.bidForm.stockType === 1 && value > 15) {
                                cb(new Error('国内现货需在15天内送达'));
                                return;
                            }
                            if (this.bidForm.stockType === 2 && value > 120) {
                                cb(new Error('非国内现货需在120天内送达'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                price: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!value) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                freightAmount: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!value) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                name: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                phone: [
                    {
                        required: true,
                        message: '手机号格式不正确',
                        trigger: 'change',
                        validator: $util.validator.phone,
                    },
                ],
                expireTimeType: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                validityTime: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!value) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                payAmount: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!value) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
            },
            options: {
                brandId: [],
                invoiceType: [...$const.options.invoiceType],
                stockType: [...$const.options.stockType],
            },
        };
    },
    watch: {
        'bidForm.price'(val) {
            if (!val) {
                this.bidForm.totalPrice = '';
                return;
            }
            let total =
                this.bidForm.price *
                _.get(this.$needIDData, 'needItemList[0].productQuantity');
            this.bidForm.totalPrice = total.toFixed(2);
        },
    },
    created() {
        this.getBrandOpts();
        this.initFormData();
    },
    methods: {
        // 获取品牌选项
        async getBrandOpts() {
            let res = await $service.brand.list({ pageSize: -1 });

            let records = _.get(res, 'data.records') || [];

            this.options.brandId = [
                ...records.map((it) => ({ text: it.name, value: it.id })),
            ];
        },
        brandSelectChange(option) {
            this.bidForm.brandId = option.value;
            this.bidForm.brandName = option.label;
        },
        async save() {
            await new Promise((resolve) =>
                this.$refs.bidForm.validate((valid) => valid && resolve()),
            );
            let params = [];
            params.push({
                ...this.bidForm,
                payAmount: this.bidForm.totalPrice,
            });
            console.log(params, 'params');
            // 抢单
            await $service.needIntent.bid(params);
            this.back();
        },
        back() {
            this.$router.push('/home/seller/index');
        },
        async initFormData() {
            let res = await $service.need.getById({
                id: this.$route.query.id,
                v: Date.now(),
            });
            let data = _.get(res, 'data') || {};
            // let billInfoFormData = needGetByIDMixin.methods.get$needIDData();
            // 返回数据需要判断code
            // let billInfoForm = billInfoFormData.data;
            let billInfoForm = data;
            let needItemList = billInfoForm.needItemList;
            let needItem = needItemList[0];
            let brandId =
                (_.get(billInfoForm, 'needItemList[0].brandId') || '') + '';
            let brandName =
                (_.get(billInfoForm, 'needItemList[0].productBrand') || '') +
                '';
            let num = _.get(billInfoForm, 'needItemList[0].productQuantity');
            let productNo = _.get(billInfoForm, 'needItemList[0].productNo');
            let unit = _.get(billInfoForm, 'needItemList[0].unit');

            this.bidForm = {
                needId: this.$route.query.id,
                needSn: billInfoForm.needSn,
                needItemId: needItem.id,
                productName: needItem.productName,
                brandId,
                brandIdDisable: !!brandId,
                brandName,
                num,
                numDisable: !!num,
                price: null,
                productNo,
                productNoDisable: !!productNo,
                totalPrice: '',
                // productPic: "",
                freightAmount: '',
                validityTime: '',
                expectedReceiptTime: 1,
                expireTimeType: '',
                //最终金额（优惠价）
                payAmount: '',
                invoiceType: '',
                unit,
                stockType: 1,
            };
        },
        priceChange(val) {
            if (val) {
                try {
                    let f = val + '';
                    let intVal = f.split('.')[0];
                    let dotVal = f.split('.')[1];
                    if (dotVal.length >= 2) {
                        // 2位数不进行赋值
                        dotVal = dotVal.slice(0, 2);
                        val = Number(`${intVal}.${dotVal}`);
                    }
                } catch (e) {}
            }

            this.$nextTick(() => {
                this.bidForm.price = val;
            });
        },
    },
};
</script>
<style lang="less" scoped>
.pop-fix {
    /deep/ .ivu-tooltip-inner {
        white-space: nowrap;
    }
}
</style>
