<template>
    <div class="bid-item">
        <div class="flex-between mb20">
            <!--  倒计时显示判断 -->
            <div class="count-down" :style="countDownDisplay">
                <CountDown :end-time="endTime" />
            </div>

            <div class="flex-v-center">
                <Button type="text" icon="arrow-left-c" @click="handleHome">
                    返回
                </Button>
            </div>
        </div>

        <DemandInfo :api-data="apiData" />
    </div>
</template>

<script>
// 询货内容
import CountDown from '@/components/CountDown.vue';
import DemandInfo from '@/pages/home/seller/share/DemandInfo.vue';
import needGetByIDMixin from '@/mixins/needGetByID.mixin';

export default {
    components: { DemandInfo, CountDown },
    mixins: [needGetByIDMixin],
    props: {
        apiData: {},
    },
    computed: {
        endTime() {
            let it = this.$needIDData;
            return dayjs(it.publishTime).add(it.expireTimeType, 'minute');
        },
        countDownDisplay() {
            let status = this.$needIDData.status;
            return {
                opacity: [2, 3].includes(status) ? 1 : 0,
            };
        },
    },
    methods: {
        handleHome() {
            if (this.$route.path === '/home/seller/bid') {
                this.$router.push('/home/seller/bidIndex');
                return;
            }
            this.$router.push('/home/seller/index');
        },
    },
};
</script>

<style lang="less" scoped>
.bid-item {
    .count-down {
        min-width: 116px;
        padding: 0 5px;
        line-height: 28px;
        text-align: center;
        background: linear-gradient(228deg, #503517 0%, #191c17 100%);
        border-radius: 2px;

        font-size: 12px;
        font-weight: 400;
        color: #ffbb33;
    }
}
</style>
